module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://acaf22f48acf42a5827bb748c8289191@o563404.ingest.sentry.io/4504057975472128","environment":"staging","enabled":true,"release":"flicket-pwa@1.8.1","tracesSampleRate":0.2,"browserTracingOptions":{"traceXHR":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Flicket Scanner","short_name":"Flicket","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#ffffff","background_color":"#ffffff","start_url":"/","display":"standalone","orientation":"natural","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/login/","/settings","/events/","/search/","/404/"],"workboxConfig":{"globDirectory":"public/","globPatterns":["**/*.{html,js,json,png,jpg,webmanifest,woff,woff2,ttf,eot,css,mjs}"],"swDest":"public/sw.js"},"appendScript":"/opt/build/repo/custom-sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
